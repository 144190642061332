<!--
 * @Date: 2023-01-31 15:59:27
 * @LastEditTime: 2023-11-29 16:13:44
 * @Description: 
 * @FilePath: \lsz-user-mp\src\views\user\checkup\list.vue
-->
<template>
  <div>
    <patientSelect @onConfirm="patientConfirm" />
    <div class="empty-text">
      线下检查报告请于检查报告完成之后的第二日进行查询！
    </div>
    <load-more :data-source="dataList" :total="total" :empty-text="'线下检查报告请于检查报告完成之后的第二日进行查询'" :loading="loading" @load-more="onLoad">
      <CheckupItem :data="dataList" />
    </load-more>
  </div>
</template>

<script>
//  import { userModel } from '@/api/user'
import loadMore from '@/assets/js/mixin/load-more'
import CheckupItem from '@/components/checkupItem/checkupItem.vue'
import {patientModel} from "../../../api/patient"

export default {
  name:'List',
  components:{
    CheckupItem
  },
  mixins: [loadMore],
  data(){
    return{
      dataList:[],
      page:1,
      pageSize:10,
      patxm:'',
      zjh:'',
      total:0,
      loading: false
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      let params={
        isPatAll: this.zjh =='' ? '1': '0',
        page: this.page,
        pageSize: this.pageSize,
        patxm: this.patxm,
        zjh: this.zjh
      }
      patientModel.jcReportList(params).then(res=>{
        this.dataList = res.data
        this.total = this.dataList.length
      }).finally(() => {
        this.loading = false
      })
    },
    //选择就诊人
    patientConfirm(value){
      this.patxm=value.text
      this.zjh=value.zjh
      this.page=1
      this.dataList=[]
      this.loading = true
      this.getList()
    },
    //分页加载更多
    onLoad(){
    }
  }
}
</script>
<style scoped>
.empty-text {
  padding: 16px;
  text-align: center;
  font-size: 28px;
  color: #DC3030 !important;
}
</style>
